<template>
    <div class="flex-grow flex">
        <div class="w-full">
            <div class="flex justify-between items-center p-2 lg:px-8">
                <div class="flex items-center">
                    <h2 v-if="week.meta">
                        Uge {{ week.meta.week.week }}
                    </h2>

                    <span
                        v-if="week.meta"
                        class="bg-gray-400 rounded-full px-3 py-1 ml-2 text-sm font-semibold text-gray-700">
                        Timer: {{ $formatNumber(week.meta.totalHours) }}
                    </span>

                    <spinner
                        v-if="loading"
                        class="ml-2"
                        size="medium"
                        color="indigo"/>
                </div>

                <a
                    href="#"
                    class="btn --primary"
                    @click.prevent="openCreate">Ny registering</a>
            </div>

            <create-or-update-entry
                :showing="modalOpen"
                :model="createOrUpdateModel"
                @close="modalOpen = false"/>
            <export-entries-modal
                :showing="showExportModal"
                @close="showExportModal = false"/>

            <div class="border-t border-b border-gray-300 bg-gray-200 flex items-center py-1 px-4 lg:px-8">
                <a
                    href="#"
                    title="Gå til i dag"
                    class="leading-none text-black no-underline pr-4"
                    @click.prevent="today">
                    <i class="material-icons text-xs">calendar_today</i>
                    <span class="font-semibold pl-1">I dag</span>
                </a>
                <a
                    href="#"
                    class="no-underline leading-none text-black pr-4 flex items-center border-r border-solid border-gray-400 mr-4"
                    @click.prevent="getWeek(week.meta.previousWeek)">
                    <i class="material-icons text-2xl">keyboard_arrow_left</i>
                    <span class="text-sm pb-px mb-px">sidste uge</span>
                </a>
                <a
                    href="#"
                    class="no-underline leading-none text-black flex items-center"
                    @click.prevent="getWeek(week.meta.nextWeek)">
                    <span class="text-sm pb-px mb-px">næste uge</span>
                    <i class="material-icons text-2xl">keyboard_arrow_right</i>
                </a>
                <div class="flex-grow hidden lg:block"></div>
                <a
                    href="#"
                    class="no-underline font-semibold text-black hidden lg:block"
                    @click.prevent="showExportModal = true">Exporter registeringer</a>
            </div>

            <div class="bg-white flex flex-col lg:flex-row">
                <div
                    v-if="week.days && week.days.length > 0"
                    class="w-8 text-right border-r border-gray-300 bg-gray-200 hidden lg:block">
                    <div class="h-12 border-b border-gray-300"></div>
                    <div
                        v-for="hour in week.days[0].hours"
                        :key="hour.hour"
                        class="border-b border-gray-300 pr-1">
                        <div class="h-12">
                            {{ $formatDate(hour.date, "H") }}
                        </div>
                    </div>
                </div>
                <div
                    v-for="(day, index) in week.days"
                    :key="day.key"
                    class="flex w-full lg:w-1/6"
                    :class="{ 'bg-indigo-50': day.isToday }">
                    <div class="w-10 text-right border-r border-gray-300 day__title lg:hidden">
                        <div class="h-12 border-b border-gray-300"></div>
                        <div
                            v-for="hour in week.days[0].hours"
                            :key="hour.hour"
                            class="border-b border-gray-300 pr-1">
                            <div class="h-12">
                                {{ $formatDate(hour.date, "H") }}
                            </div>
                        </div>
                    </div>
                    <div class="w-full relative">
                        <c-entry
                            v-for="entry in day.entries"
                            :key="entry.id"
                            :entry="entry"
                            @click="openEdit(entry)"/>

                        <div
                            class="day__title px-3 h-12 flex flex-col justify-center border-b border-gray-300"
                            :class="{ 'border-r': index + 1 != week.days.length }">
                            <span class="block text-lg font-semibold text-gray-700 leading-none">
                                {{ $formatDate(day.date, "DD. MMM") }}
                                <span
                                    class="inline-block bg-gray-400 rounded-full px-2 py-1 ml-1 text-sm font-semibold text-gray-700">
                                    {{ $formatNumber(day.duration) }}
                                </span>
                            </span>
                            <span class="text-gray-700 text-sm leading-none">{{ $formatDate(day.date, "dddd") }}</span>
                        </div>

                        <div
                            v-for="hour in day.hours"
                            :key="hour.key"
                            class="border-b border-gray-300"
                            :class="{ 'border-r': index + 1 != week.days.length }">
                            <div
                                class="hour relative border-b border-gray-300 border-dashed h-6 cursor-pointer"
                                @click="openCreateFromHour(hour.date)"></div>
                            <div
                                class="hour h-6 cursor-pointer"
                                @click="openCreateFromHour(hour.date30Min)"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="h-8"></div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import calendarService from '../core/services/calendar/calendar.service';
import moment from 'moment';
import CreateOrUpdateEntry from '@/components/CreateOrUpdateEntry.vue';
import CEntry from '@/components/Entry.vue';
import { Entry } from '../_typedef/missile';
import { Calendar } from '@/_typedef/calendar.models';
import Spinner from '@/components/Spinner.vue';
import ExportEntriesModal from '@/components/Export-Entries-Modal.vue';
import teamsService from '@/core/services/teams/teams.service';
import taskTypesService from '../core/services/tasktypes/taskTypes.service';

@Component({
    name: 'home',
    components: {
        CreateOrUpdateEntry,
        'c-entry': CEntry,
        Spinner,
        ExportEntriesModal
    }
})
export default class Home extends Vue {
    modalOpen: boolean = false;
    createOrUpdateModel: any = {};
    loading: boolean = false;
    showExportModal: boolean = false;

    async created() {
        await this.today();
    }

    get week(): Calendar {
        return calendarService.week;
    }

    async today() {
        this.toggleLoading();

        await calendarService.getWeek();

        this.toggleLoading();
    }

    async getWeek(date: string | Date) {
        this.toggleLoading();

        await calendarService.getWeek({ day: new Date(date) });

        this.toggleLoading();
    }

    toggleLoading(): void {
        this.loading = !this.loading;
    }

    openCreate() {
        const now = moment(new Date());
        this.createOrUpdateModel = {
            fromDate: now.format('YYYY-MM-DD'),
            toDate: now.format('YYYY-MM-DD'),
            fromHours: now.format('HH:00'),
            toHours: now.format('HH:30'),
            team: teamsService.teams.filter(x => x.isDefaultOption)[0]?.id ?? null,
            taskType: taskTypesService.taskTypes.filter(x => x.isDefaultOption)[0]?.id ?? null
        };

        this.modalOpen = true;
    }

    openCreateFromHour(date: string | Date) {
        const now = moment.parseZone(date).local();

        this.createOrUpdateModel = {
            fromDate: now.format('YYYY-MM-DD'),
            toDate: now.format('YYYY-MM-DD'),
            fromHours: now.format('HH:mm'),
            toHours: now.add(30, 'minutes').format('HH:mm'),
            team: teamsService.teams.filter(x => x.isDefaultOption)[0]?.id ?? null,
            taskType: taskTypesService.taskTypes.filter(x => x.isDefaultOption)[0]?.id ?? null
        };

        this.modalOpen = true;
    }

    openEdit(entry: Entry) {
        this.createOrUpdateModel = {
            fromDate: moment(entry.fromDate).local().format('YYYY-MM-DD'),
            fromHours: moment(entry.fromDate).local().format('HH:mm'),

            toDate: moment(entry.toDate).local().format('YYYY-MM-DD'),
            toHours: moment(entry.toDate).local().format('HH:mm'),

            team: entry.teamId,
            taskType: entry.taskTypeId,
            comment: entry.comment,
            free: entry.free,
            id: entry.id
        };

        this.modalOpen = true;
    }
}
</script>