import { CalendarViewModel, MetaData, Iso8601YearWeek, Entry } from './missile';
import moment, { Moment } from 'moment';

export class Hour {
    private readonly _date: Moment;
    private readonly model: Entry[];

    constructor(hour: number, date: Moment, entries: Entry[]) {
        this._date = date;
        this.hour = hour;

        const lower = moment(date);
        const max = moment(lower).add(1, 'hours');

        this.model = entries.filter((x: Entry) => {
            const from = moment(x.fromDate);
            return from.isBetween(lower, max, undefined, '[)');
        });
    }

    get date(): Date {
        return this._date.toDate();
    }

    get date30Min(): Date {
        return this._date.add(30, 'minutes').toDate();
    }

    get entries(): Entry[] {
        return this.model;
    }

    get key(): string {
        return this._date.toString();
    }

    hour: number;
}

export class Day {
    private readonly _date: Moment;

    constructor(date: Moment, entries: Entry[]) {
        this._date = date;

        this.hours = [];
        for (let i = 7; i < 22; i++) {
            const now = moment(date);
            now.hours(i);
            now.minutes(0);

            this.hours.push(new Hour(i, now, entries));
        }
    }

    get date(): Date {
        return this._date.toDate();
    }

    get isToday(): boolean {
        return this._date.isSame(moment(), 'day');
    }

    get key() : string {
        return this._date.toString();
    }

    get duration(): number {
        return this.entries
            .filter((x: Entry) => !x.free)
            .reduce((total, entry) => total + entry.duration, 0);
    }

    get entries(): Entry[] {
        return this.hours
            .flatMap(x => x.entries);
    }

    hours: Hour[];
}

export class Calendar {
    private readonly model: CalendarViewModel;

    constructor(model: CalendarViewModel) {
        this.model = model;

        this.days = [];
        for (let i = 0; i < 7; i++) {
            const now = moment(model.firstDayOfWeek);

            now.add(i, 'days');
            this.days.push(new Day(now, model.entries));
        }
    }

    get meta(): MetaData {
        return this.model.meta;
    }

    get week(): Iso8601YearWeek {
        return this.model.meta.week;
    }

    days: Day[];
}