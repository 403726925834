import Vue from 'vue';
import moment from 'moment';
import endpointService from '../endpoint.service';
import { CalendarViewModel, WeeklyCalendarRequest, CreateEntryRequest, UpdateEntryRequest } from '@/_typedef/missile';
import { Calendar } from '@/_typedef/calendar.models';

interface ICalendarState {
    week: Calendar;
}

class CalendarService {
    private state: ICalendarState = Vue.observable({
        week: {} as Calendar
    });

    public get week(): Calendar {
        return this.state.week;
    }

    public async getWeek(request?: WeeklyCalendarRequest): Promise<Calendar> {
        const res = await endpointService.get<CalendarViewModel>('calendar', request);

        this.state.week = new Calendar(res);

        return this.state.week;
    }

    public async createEntry(request: CreateEntryRequest): Promise<Calendar> {
        const res = await endpointService.post<CalendarViewModel>('calendar/entry', request, 'Calendar');
        this.state.week = {} as Calendar;
        this.state.week = new Calendar(res);

        return this.state.week;
    }

    public async updateEntry(id: number, request: UpdateEntryRequest): Promise<Calendar> {
        const res = await endpointService.put<CalendarViewModel>(`calendar/entry/${id}`, request, 'Calendar');

        this.state.week = new Calendar(res);

        return this.state.week;
    }

    public async deleteEntry(id: number): Promise<Calendar> {
        const res = await endpointService.delete<CalendarViewModel>(`calendar/entry/${id}`, { date: moment(this.state.week.days[0].date).format('YYYY-MM-DDTHH:mm:00') });

        this.state.week = new Calendar(res);

        return this.state.week;
    }
}

export default new CalendarService();