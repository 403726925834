<template>
    <v-modal
        :showing="showing"
        @close="close">
        <div>
            <h2>Ny registering</h2>
            <validation-observer v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(create)">
                    <div class="form-group flex">
                        <div class="w-1/2">
                            <v-input
                                id="startDate"
                                v-model="model.fromDate"
                                :disabled="recurring"
                                label="Start"
                                rule="required"
                                name="Start"/>
                        </div>

                        <div class="flex items-end pl-3 w-1/2">
                            <input
                                v-model="model.fromHours"
                                type="text"
                                name="fromHours"
                                list="fromHoursList">
                            <datalist id="fromHoursList">
                                <option
                                    v-for="min in minuteOptions"
                                    :key="min"
                                    :value="min"/>
                            </datalist>
                        </div>
                    </div>

                    <div class="form-group flex">
                        <div class="w-1/2">
                            <v-input
                                v-model="model.toDate"
                                :disabled="recurring"
                                label="Slut"
                                rule="required"
                                name="Slut"/>
                        </div>

                        <div class="pl-3 w-1/2 flex flex-col items-end justify-end">
                            <div
                                v-if="!isEditing"
                                class="form-check text-right">
                                <input
                                    id="recurring"
                                    v-model="recurring"
                                    type="checkbox"
                                    tabindex="-1"
                                    @click="toggleRecurring">
                                <label
                                    for="recurring"
                                    class>Gentag</label>
                            </div>
                            <input
                                v-model="model.toHours"
                                type="text"
                                name="toHours"
                                list="toHoursList">
                            <datalist id="toHoursList">
                                <option
                                    v-for="min in minuteOptions"
                                    :key="min"
                                    :value="min"/>
                            </datalist>
                        </div>
                    </div>

                    <div
                        v-if="recurring"
                        class="form-group flex">
                        <div class="w-1/2">
                            <v-input
                                v-model="recurringModel.endDate"
                                label="Gentag til og med"
                                rule="required"
                                name="Slut"/>
                        </div>

                        <div class="w-1/2 pl-3">
                            <label>Frekvens</label>
                            <v-select
                                v-model="recurringModel.interval"
                                rule="required"
                                name="Interval">
                                <option
                                    disabled
                                    :value="null">
                                    Interval
                                </option>
                                <option
                                    v-for="option in recurringOptions"
                                    :key="option.value"
                                    :value="option.value">
                                    {{ option.text }}
                                </option>
                            </v-select>
                        </div>
                    </div>

                    <div class="form-group">
                        <label>Vælg et hold</label>
                        <v-select
                            v-model="model.team"
                            rule="required"
                            name="Hold">
                            <option
                                disabled
                                :value="null">
                                Vælg et hold
                            </option>
                            <option
                                v-for="team in teams"
                                :key="team.id"
                                :value="team.id">
                                {{ team.displayName }}
                            </option>
                        </v-select>
                    </div>

                    <div class="form-group">
                        <label>Vælg en opgavetype</label>
                        <v-select
                            v-model="model.taskType"
                            rule="required"
                            name="Opgavetype">
                            <option
                                disabled
                                :value="null">
                                Vælg en opgavetype
                            </option>
                            <option
                                v-for="taskType in taskTypes"
                                :key="taskType.id"
                                :value="taskType.id">
                                {{ taskType.displayName }}
                            </option>
                        </v-select>
                    </div>

                    <div class="form-group form-check">
                        <input
                            id="free"
                            v-model="model.free"
                            type="checkbox">
                        <label
                            for="free"
                            class>Udelad fra tidsforbrug</label>
                    </div>

                    <div class="form-group">
                        <label>Kommentar</label>
                        <textarea v-model="model.comment"></textarea>
                    </div>

                    <div v-if="errors.length > 0">
                        <div
                            v-for="error in errors"
                            :key="error"
                            class="my-2 px-3 py-2 rounded-sm bg-red-600 text-white text-sm">
                            {{ error }}
                        </div>
                    </div>

                    <div class="form-group text-right">
                        <button
                            v-if="isEditing"
                            type="button"
                            class="btn float-left"
                            tabindex="-1"
                            @click="deleteEntry">
                            Slet
                        </button>

                        <button
                            type="button"
                            class="btn mr-3"
                            tabindex="-1"
                            @click="close">
                            Luk
                        </button>
                        <button
                            v-if="isEditing"
                            type="submit"
                            class="btn --primary"
                            :disabled="saving">
                            Opdater
                        </button>
                        <button
                            v-else
                            type="submit"
                            class="btn --primary"
                            :disabled="saving">
                            Opret
                        </button>
                    </div>
                </form>
            </validation-observer>
        </div>
    </v-modal>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import moment from 'moment';
import { Prop, Watch } from 'vue-property-decorator';
import calendarService from '../core/services/calendar/calendar.service';
import teamsService from '../core/services/teams/teams.service';
import taskTypesService from '../core/services/tasktypes/taskTypes.service';
import {
    TeamViewObject,
    TaskTypeViewObject,
    CreateEntryRequest,
    UpdateEntryRequest,
    IntervalOption
} from '@/_typedef/missile';
import {
    Displayable
} from '@/_typedef/common.models';

@Component({})
export default class CreateOrUpdateEntry extends Vue {
    @Prop({ default: false }) showing: boolean;
    @Prop() model!: any;

    recurring: boolean = false;
    recurringModel: any = {};
    errors: any[] = [];
    saving: boolean = false;

    recurringOptions: Displayable[] = [
        {
            value: IntervalOption.Weekly,
            text: 'Hver uge'
        },
        {
            value: IntervalOption.BiWeekly,
            text: 'Hver anden uge'
        },
        {
            value: IntervalOption.Monthly,
            text: 'Hver måned'
        }
    ];

    get minuteOptions(): string[] {
        const dt = new Date(1970, 0, 1, 7, 0, 0, 0);
        const times = [];
        for (let i = 1; i <= 30; i++) {
            times.push(moment(dt).add(30 * i, 'minutes'));
        }
        return times.map((dt: any) => moment(dt).format('HH:mm'));
    }

    created() {
        this.recurringModel = {
            interval: IntervalOption.Weekly,
            endDate: moment(this.model.fromDate).format('YYYY-MM-DD')
        };
    }

    // We are in edit mode if entry is already created
    get isEditing(): boolean {
        return this.model.id;
    }

    get teams(): TeamViewObject[] {
        return teamsService.teams;
    }

    get taskTypes(): TaskTypeViewObject[] {
        return taskTypesService.taskTypes;
    }

    async create() {
        this.errors = [];

        if (this.saving) {
            return;
        }

        this.saving = true;

        const fromDate: any = moment(
            `${this.model.fromDate} ${this.model.fromHours}`
        );

        const toDate: any = moment(
            `${this.model.toDate} ${this.model.toHours}`
        );

        const request: CreateEntryRequest = {
            fromDate: fromDate.utcOffset(0, true).format('YYYY-MM-DDTHH:mm:00'),
            toDate: toDate.utcOffset(0, true).format('YYYY-MM-DDTHH:mm:00'),
            teamId: Number(this.model.team),
            taskTypeId: Number(this.model.taskType),
            free: this.model.free,
            comment: this.model.comment,
            utcOffset: fromDate.utcOffset()
        };

        try {
            if (this.isEditing) {
                await calendarService.updateEntry(
                    this.model.id,
                    request as UpdateEntryRequest
                );
            } else {
                if (this.recurring) {
                    request.recurring = this.recurringModel;
                }

                await calendarService.createEntry(request);
            }

            this.close();
        } catch (errors: any) {
            this.errors = errors;
        }

        this.saving = false;
    }

    async deleteEntry() {
        if (confirm('Vil du slette denne registering?')) {
            await calendarService.deleteEntry(this.model.id);

            this.close();
        }
    }

    close() {
        this.$emit('close');
    }

    toggleRecurring() {
        this.recurring = !this.recurring;

        this.model.toDate = this.model.fromDate;
    }

    @Watch('showing')
    focusOnOpen(isShowing: boolean): void {
        Vue.nextTick(() => {
            if (isShowing) {
                document.getElementById('startDate')?.focus();
            }
        });
    }
}
</script>