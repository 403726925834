<template>
    <div
        :key="entry.id"
        class="entry"
        :class="entry.taskType.color.toLowerCase()"
        :style="{ 'height': `${height}px`, 'top': `${offset}px` }"
        :title="`${entry.taskType.displayName} fra ${$formatDate(entry.fromDate)} til ${$formatDate(entry.toDate)}`"
        @click.stop="openEdit(entry)">
        <i
            v-if="entry.free"
            class="material-icons pr-px"
            title="Udeladt fra timeforbrug">
            timer_off
        </i>

        <i
            v-if="entry.recurringId"
            class="material-icons pr-px"
            title="Med fast frekevens">
            autorenew
        </i>

        <div class="font-semibold text-sm pt-1">
            {{ entry.team.displayName }}
        </div>

        <span
            v-if="height >= 48"
            class="text-xs">{{ entry.taskType.displayName }}</span>

        <!-- eslint-disable-next-line -->
        <span v-if="height >= 88" class="entry__comment" v-html="entry.formattedComment"></span>

        <span
            v-if="height >= 71"
            class="block font-bold text-sm absolute bottom-0 right-0 pb-1 pr-1">{{
                $formatNumber(entry.duration) }}</span>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Entry } from '../_typedef/missile';
import moment from 'moment';

@Component({
    name: 'c-entry'
})
export default class CEntry extends Vue {
    @Prop() entry: Entry;

    get height(): number {
        const diff = moment(this.entry.toDate).diff(this.entry.fromDate);
        const duration = moment.duration(diff);

        const durationInPixels = duration.asMinutes() * 0.8;

        if (durationInPixels < 25) return 23; // calendar 30min is 23 px and is the min height

        return durationInPixels;
    }

    get offset(): number {
        const hours = moment(this.entry.fromDate).hours();
        return (((hours - 6) * 60) * 0.8) + (moment(this.entry.fromDate).minutes() * 0.80) + hours - 7;
    }

    openEdit(entry: Entry) {
        this.$emit('click', entry);
    }
}
</script>