<template>
    <v-modal
        :showing="showing"
        @close="close">
        <div>
            <h2>Eksporter registeringer</h2>
            <validation-observer v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(exportCsv)">
                    <div class="form-group">
                        <v-input
                            v-model="model.fromDate"
                            label="Start"
                            rule="required"
                            name="Start"
                            placeholder="YYYY-MM-DD (2020-01-25)"/>
                    </div>

                    <div class="form-group">
                        <v-input
                            v-model="model.toDate"
                            label="Slut"
                            rule="required"
                            name="Slut"
                            placeholder="YYYY-MM-DD (2020-12-31)"/>
                    </div>

                    <div class="form-group form-check">
                        <input
                            id="free"
                            v-model="model.free"
                            type="checkbox">
                        <label
                            for="free"
                            class>Inkluderer udeladte registeringer</label>
                    </div>

                    <div class="form-group text-right">
                        <button
                            type="button"
                            class="btn mr-3"
                            tabindex="-1"
                            @click="close">
                            Luk
                        </button>
                        <button
                            type="submit"
                            class="btn --primary"
                            :disabled="downloading">
                            Exporter
                        </button>
                    </div>
                </form>
            </validation-observer>
        </div>
    </v-modal>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import moment from 'moment';
import { Prop, Watch } from 'vue-property-decorator';
import authenticationService from '@/core/services/account/authentication.service';

    @Component({})
export default class ExportEntriesModal extends Vue {
        @Prop({ default: false }) showing: boolean;

        model: any = {
            fromDate: moment().add(-1, 'year').format('YYYY-MM-DD'),
            toDate: moment().format('YYYY-MM-DD')
        };

        downloading = false;

        async exportCsv() {
            this.downloading = true;

            const options = {
                headers: {
                    Authorization: 'Bearer ' + authenticationService.token
                }
            };

            fetch(this.getHost() + '/api/calendar/entry/export/csv?' + this.toQueryString(this.model), options)
                .then((res) => res.blob())
                .then((blob) => {
                    const objUrl = window.URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    link.href = objUrl;
                    link.download = 'export.csv';
                    link.click();

                    // For Firefox it is necessary to delay revoking the ObjectURL.
                    setTimeout(() => {
                        window.URL.revokeObjectURL(objUrl);
                    }, 250);

                    this.downloading = false;
                });
        }

        toQueryString(params: any) {
            return Object.keys(params)
                .map((key) => key + '=' + params[key])
                .join('&');
        }

        getHost() {
            return window.location.origin;
        }

        close() {
            this.$emit('close');
        }

        @Watch('showing')
        focusOnOpen(isShowing: boolean): void {
            Vue.nextTick(() => {
                if (isShowing) {
                    this.model = {
                        fromDate: moment().add(-1, 'year').format('YYYY-MM-DD'),
                        toDate: moment().format('YYYY-MM-DD')
                    };
                }
            });
        }
}
</script>